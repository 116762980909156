<template>
  <div class="message">
    <h1>404</h1>
    <h3>This page has expired!</h3>
    <p>The page you are looking for may have been moved, deleted, or possibly never existed.</p>
  </div>
</template>
<script>
export default {
}
</script>
<style scoped>
.message {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: Arial, sans-serif;
}
.message h1 {
  font-size: 8em;
  color: #00848E;
  margin: 0;
}
.message h3 {
  font-size: 2.5em;
  color: #00848E;
  font-weight: 300;
  margin-top: 10px;
}
.message p {
  font-size: 1.2em;
  color: #444;
  margin: 20px 0;
}
button {
  background-color: #00848E;
  border: 2px solid #00848E;
  color: #fff;
  padding: 10px 20px;
  font-size: 1.2em;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
}
button:hover {
  background-color: #005F63;
  border-color: #005F63;
}
button:focus {
  box-shadow: 0 0 0 3px rgba(0, 132, 142, 0.4);
}
</style>